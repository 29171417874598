.dropdown-menu {
  position: absolute;
  align-self: center;
  top: 45px;
  text-align: start;
}

.dropdown-menu li {
  background: #fff;
  cursor: pointer;

}

.dropdown-menu li:hover {
  background: #0eb8a9;
  border-radius: 4px;
  color: #fff;
  transition: all 0.2s ease-out;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.dropdown-link:hover {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
